import React from 'react';
import Header from './header';
import SubHeader from './SubHeader';
import HeroSection from './Hero';
import Footer from './Footer';
import Content from './Content';

function LandingPage( ) {
  return (
    <div>
        <Header />
        <SubHeader />
        <HeroSection />
        <Content />
        <Footer />
      {/* <h1>Bienvenido a Nuestra Aplicación</h1>
      <p>Encuentra el contrato perfecto para tus necesidades</p>
      <Link to="/crlist" className="btn btn-primary">Ver Contratos</Link> */}
    </div>
  );
}

export default LandingPage;
