import React from 'react';
import { Link } from 'react-router-dom';
// Importa el logo de tu proyecto. Asegúrate de ajustar la ruta al archivo correcto.
import logo from '../../assets/logos/logo_blanco.svg';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark px-4 mt-2">
      <Link className="navbar-brand" to="/">
        <img src={logo} alt="Logo" style={{maxHeight: '40px'}}/>
      </Link>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/features">Features</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/company">Company</Link>
          </li>
        </ul>
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/login">Log in</Link>
          </li>
          <li className="nav-item">
            <Link className="btn btn-primary" style={{backgroundColor: '#2658f5'}} to="/try-opertime" role="button">Try Opertime</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
