import React from 'react';
import { Link } from 'react-router-dom';

const SubHeader = () => {
    return (
        <nav className="subheader navbar navbar-expand navbar-dark px-4">
            <div className="navbar-collapse">
                <ul className="navbar-nav">
                    <li className="nav-item pe-4">
                        <Link className="nav-link ps-0 pt-0" to="/categoria1">Nuestra historia</Link>
                    </li>
                    <li className="nav-item pe-4">
                        <Link className="nav-link ps-0 pt-0" to="/categoria2">El problema legal</Link>
                    </li>
                    <li className="nav-item pe-4">
                        <Link className="nav-link ps-0 pt-0" to="/categoria2">Nuestro impacto</Link>
                    </li>
                    <li className="nav-item pe-4">
                        <Link className="nav-link ps-0 pt-0" to="/categoria2">Como inovamos?</Link>
                    </li>
                    <li className="nav-item pe-4">
                        <Link className="nav-link ps-0 pt-0" to="/categoria2">Nuestra IA</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default SubHeader;
